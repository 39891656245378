@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600;700;900&display=swap');

td,
th {
  overflow: hidden;
  padding: 8px !important;
  word-break: break-word;
  text-align: left !important;
}

/* thead > tr {
  width: 100% !important;
} */

.MuiTable-root .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: #fff;
}

.MuiTable-root .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  color: rgba(255, 255, 255, 0.7);
}

/* Change scrollbar width and height */
::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
  height: 8px; /* Height of horizontal scrollbar */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 2px solid #f1f1f1;
}

/* Hover effect for scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
